import { Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';

import './CardArtist.styl';
import Image from '../Image/index';

const CardArtist = ({ fields, titreP }) => {
    const artistName = titreP.split(' ');
    const { avatarSrc, patchworkSrc, path } = fields;

    return (
        <div className='card-container'>
            <Link
                className='cell'
                style={{ height: '100%', width: '100%' }}
                to={path}
            >
                <div className='card-artist-header'>
                    <div className='artist-name'>
                        <div className='name'>{artistName[0]}</div>
                        <div className='lastname'>{artistName[1]}</div>
                    </div>
                    <div className='avatar'>
                        <Image
                            alt={`avatar-${titreP}`}
                            image={avatarSrc.childImageSharp.gatsbyImageData}
                            objectFit='cover'
                            style={{
                                flexGrow: 1,
                                height: '100%',
                            }}
                        />
                    </div>
                </div>

                <div className='artist-patchwork'>
                    <Image
                        alt={`avatar-${titreP}`}
                        image={patchworkSrc.childImageSharp.gatsbyImageData}
                        objectFit='cover'
                        style={{
                            flexGrow: 1,
                            height: '100%',
                        }}
                    />
                </div>
            </Link>
        </div>
    );
};

CardArtist.propTypes = {
    fields: PropTypes.object.isRequired,
    titreP: PropTypes.string.isRequired,
};

export default CardArtist;
