import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Container from '../views/components/Container/index';
import SEO from '../views/components/SEO';
// import Hero from '../views/layouts/Hero';
import MainLayout from '../views/layouts/MainLayout';
import CardArtist from '../views/components/CardArtist/CardArtist';

import './partnership.styl';

const PartnershipPage = ({
    data,
    location,
    pageContext: { locale, translations },
}) => {
    const list = data.artists.edges.map((n) => n.node);

    return (
        <MainLayout
            locale={locale}
            translationsPaths={translations}
            neverTransparent
        >
            <SEO
                title='pages.partnership.headerTitle'
                description='pages.partnership.description'
                location={location}
                translationsPaths={translations}
            />

            <main id='content' className='page-partnership gap'>
                <section className='group-intro'>
                    <Container>
                        <div className='intro'>
                            <FormattedMessage
                                tagName='h1'
                                id='pages.partnership.hero.h1'
                            />
                            <FormattedMessage
                                tagName='p'
                                id='pages.partnership.hero.p1'
                            />
                        </div>
                    </Container>
                </section>

                <section>
                    <Container>
                        <div className='partnership-list-container'>
                            {list.map((artist) => (
                                <CardArtist key={artist.id} {...artist} />
                            ))}
                        </div>
                    </Container>
                </section>
            </main>
        </MainLayout>
    );
};

PartnershipPage.propTypes = {
    data: PropTypes.shape({
        artists: PropTypes.object.isRequired,
        hero: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        translations: PropTypes.array.isRequired,
    }).isRequired,
};

export default PartnershipPage;

export const pageQuery = graphql`
    query ($locale: String!) {
        hero: file(
            sourceInstanceName: { eq: "hero" }
            relativePath: { eq: "home.jpg" }
        ) {
            ...BgImages
        }

        artists: allProducts(
            filter: {
                lang: { eq: $locale }
                fields: { section: { eq: "partnership" } }
            }
        ) {
            edges {
                node {
                    ...ArtistCard
                }
            }
        }
    }
`;
